import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from './reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['account', 'app'],
    serialize: true,
};

const enhancer =
    process.env.REACT_APP_ENV === 'production'
        ? applyMiddleware()
        : composeWithDevTools({ maxAge: 200 })(applyMiddleware());

const persistedReducer = persistReducer(persistConfig, reducer);
export const store = createStore(persistedReducer, enhancer);

export const persistor = persistStore(store);
