import { Button } from '@mui/material';
import { formFieldHeight } from '../../styles/style';

export default function FormButton({ type, children, sx, onClick }) {
    return (
        <Button
            type={type}
            fullWidth
            size="large"
            variant="contained"
            sx={{ height: formFieldHeight, px: '0.125rem', fontSize: '1.125rem', flex: 1, ...sx }}
            onClick={() => {
                onClick && onClick();
            }}>
            {children}
        </Button>
    );
}
