// https://reactjsexample.com/react-project-to-scan-and-write-into-nfc-tags-using-the-web-nfc-api/

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import AlertDialog from './components/molecules/AlertDialog';
import NotificationDialog from './components/molecules/NotificationDialog';
import PageLoading from './components/molecules/PageLoading';
import useVhVw from './hooks/useVhVw';
import AppRoute from './router/AppRouter';
import { persistor, store } from './state/store';

const App = () => {
    useVhVw();
    return (
        <>
            <PageLoading />
            <NotificationDialog />
            <AlertDialog />
            <AppRoute />
        </>
    );
};

const Apps = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
};

export default Apps;
