export const ERROR_MESSAGE = {
    DEFAULT_ERROR: 'エラーが発生しました。<br>時間をおいてから再度アクセスしてください。',
    CONNECT_ERROR: '接続できませんでした。<br>接続環境をご確認の上再度アクセスしてください。',
    PAGE_NOT_FOUND: 'お探しのページが見つかりません。<br>正しいURLを指定してください。',
};

export const API = {
    kyc: '/kyc',
    login: '/login',
    signature: '/signature',
};

export const FORMAT = {
    FULLDATE: 'yyyy年M月d日H:mm:ss',
    DATE: 'yyyy年M月d日',
};

export const KYC_TYPE = {
    LOGIN: 'login',
    COLLATION: 'collation',
};
