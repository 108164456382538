import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Avatar, Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { sleep } from '../../helpers/common';
import { FlexBox, KycResult, KycResultInfo } from '../../styles/style';

export default function KycResultAnimate({ kycResult, animate }) {
    const theme = useTheme();
    const caResultsLength = kycResult.caResults ? kycResult.caResults.length : 0;
    const hasCaResults = caResultsLength > 0;
    const [caResultAnimate, setCaResultAnimate] = useState({});
    const [adminResultAnimate, setAdminResultAnimate] = useState(false);
    const isUnmounted = useRef(false);
    const delay = 250;
    const duration = 750;

    useEffect(() => {
        async function startAnimate() {
            await sleep(delay);
            if (hasCaResults) {
                for (let i = 0; i < caResultsLength; i++) {
                    if (isUnmounted.current) {
                        return;
                    }
                    const bcId = kycResult.caResults[i].caBcUserId;
                    setCaResultAnimate(result => {
                        return { ...result, [bcId]: true };
                    });
                    await sleep(duration);
                }
            }
            if (isUnmounted.current) {
                return;
            }
            setAdminResultAnimate(true);
        }
        if (animate) {
            startAnimate();
        }
        return () => {
            isUnmounted.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resultColor = result => {
        return result ? theme.palette.success.light : theme.palette.error.light;
    };

    return (
        <>
            <KycResult>
                <Box sx={{ width: theme.spacing(5), height: theme.spacing(5) }}>
                    {(animate ? adminResultAnimate : true) && (
                        <Avatar
                            sx={{
                                bgcolor: resultColor(kycResult.adminResult),
                                ...(animate && {
                                    animation: `FadeIn ${duration}ms`,
                                }),
                            }}>
                            {kycResult.adminResult ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
                        </Avatar>
                    )}
                </Box>
                <KycResultInfo>
                    <Box>
                        <b>契約ID: </b>
                        {kycResult.contractId}
                    </Box>
                    <Box>
                        <b>照合ID: </b>
                        {kycResult.authId}
                    </Box>
                </KycResultInfo>
            </KycResult>
            {hasCaResults && (
                <Box sx={{ my: theme.spacing(1), ml: theme.spacing(6) }}>
                    {kycResult.caResults.map((ca, caIdx) => (
                        <FlexBox key={caIdx} sx={{ justifyContent: 'flex-start' }}>
                            <FlexBox
                                sx={{ width: theme.spacing(3.25), height: theme.spacing(3.25), mr: theme.spacing(1) }}>
                                {(animate ? caResultAnimate[ca.caBcUserId] : true) && (
                                    <Box
                                        sx={{
                                            color: resultColor(ca.caResult),
                                            ...(animate && {
                                                animation: `FadeIn ${duration}ms`,
                                            }),
                                        }}>
                                        {ca.caResult ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
                                    </Box>
                                )}
                            </FlexBox>
                            <Box
                                sx={{
                                    color: animate
                                        ? caResultAnimate[ca.caBcUserId]
                                            ? resultColor(ca.caResult)
                                            : '#666666'
                                        : resultColor(ca.caResult),
                                    ...(animate &&
                                        caResultAnimate[ca.caBcUserId] && {
                                            animation: ca.caResult
                                                ? `ColorSuccess ${duration}ms`
                                                : `ColorError ${duration}ms`,
                                        }),
                                }}>
                                <b>認証局ID: </b>
                                {ca.caBcUserId}
                            </Box>
                        </FlexBox>
                    ))}
                </Box>
            )}
        </>
    );
}
