import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FaceShooting from '../components/organisms/FaceShooting';
import { sleep } from '../helpers/common';
import { setKycImage } from '../state/app';
import { setSuccessAlert } from '../state/page';

export default function Registration() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleShoot = async image => {
        dispatch(setKycImage(image));
        await sleep(1000);
        dispatch(setSuccessAlert('登録が完了しました'));
    };

    return (
        <>
            <FaceShooting onBack={() => navigate(-1)} onShoot={handleShoot} />
        </>
    );
}
