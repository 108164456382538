import { Box, TextField, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { formFieldHeight } from '../../styles/style';

export default function FormTextField({
    name,
    defaultValue,
    control,
    type,
    label,
    placeholder,
    shrink,
    size,
    error,
    helperText,
    sx,
    inputProps,
}) {
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%' }}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue || ''}
                render={({ field }) => (
                    <TextField
                        {...field}
                        type={type}
                        label={label}
                        placeholder={placeholder}
                        variant="outlined"
                        error={error}
                        fullWidth
                        autoComplete="off"
                        inputProps={inputProps}
                        margin="dense"
                        size={size}
                        InputLabelProps={{
                            shrink,
                        }}
                        sx={{
                            '& div': { background: '#fff', height: formFieldHeight },
                            mt: '0',
                            mb: '2px',
                            height: formFieldHeight,
                            ...sx,
                        }}
                    />
                )}
            />
            <Box
                sx={{
                    height: '1.25rem',
                    color: theme.palette.error.main,
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    ml: '10px',
                    mb: '4px',
                }}>
                {helperText || ''}
            </Box>
        </Box>
    );
}
