import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import contractContent from '../assets/images/contract.png';
import FormButton from '../components/atom/FormButton';
import Footer from '../components/molecules/Footer';
import GuideDialog from '../components/molecules/GuideDialog';
import Header from '../components/molecules/Header';
import Stamped from '../components/molecules/Stamped';
import { CONTRACT_PATH, HISTORY_PATH, INDEX_PATH } from '../router/routerPath';
import { resetKycResult, stampedPositionSelector } from '../state/app';
import { ContractContainer, ContractContent, PageContainer, PageContent } from '../styles/style';

export default function StampedContract() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stampedPosition = useSelector(stampedPositionSelector);

    const handleComplete = () => {
        dispatch(resetKycResult());
        navigate(INDEX_PATH);
    };

    return (
        <>
            <Header onBack={() => navigate(CONTRACT_PATH)} />
            <PageContainer hasHeader paddingSm>
                <GuideDialog message="押印が完了しました。" />
                <PageContent hasGuide hasFooter>
                    <ContractContainer sign>
                        <ContractContent component="img" src={contractContent}></ContractContent>
                        {stampedPosition && <Stamped x={stampedPosition.x} y={stampedPosition.y} />}
                    </ContractContainer>
                </PageContent>
                <Footer>
                    <FormButton onClick={() => navigate(HISTORY_PATH)}>履歴を確認</FormButton>
                    <FormButton onClick={handleComplete}>最初から</FormButton>
                </Footer>
            </PageContainer>
        </>
    );
}
