import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { loadingSelector } from '../../state/page';

export default function PageLoading() {
    const loading = useSelector(loadingSelector);

    return (
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" size="4rem" />
        </Backdrop>
    );
}
