import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { LOGIN_PATH } from './routerPath';
import { accountTokenSelector } from '../state/account';
import { setCheckpoint } from '../state/app';
import { Box } from '@mui/material';

export default function SecurityRoute({ element, path, isAuth, bg }) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const token = useSelector(accountTokenSelector);
    const [isRender, setIsRender] = useState(false);

    const checkSecurity = () => {
        if (isAuth) {
            if (!token) {
                return LOGIN_PATH;
            }
        }

        return null;
    };

    const historySecurity = () => {
        const pathSecurity = checkSecurity();
        if (pathSecurity && pathSecurity !== path) {
            dispatch(setCheckpoint(path));
            navigate(pathSecurity);
        } else {
            setIsRender(true);
        }
    };

    useEffect(() => {
        if (element) {
            historySecurity();
        } else {
            setIsRender(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return isRender ? (
        <Box component="main" className={`main-container ${bg || ''}`} id="main-container">
            {element}
        </Box>
    ) : (
        <></>
    );
}
