import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FaceShooting from '../components/organisms/FaceShooting';
import { API, KYC_TYPE } from '../constants/common';
import { dataURLtoFile } from '../helpers/common';
import { INDEX_PATH, LOGIN_HISTORY_ANIMATION_PATH } from '../router/routerPath';
import { post } from '../services/request';
import { accountTokenSelector } from '../state/account';
import { kycImageSelector, setKycResultHistory } from '../state/app';
import { closeAlert, setSuccessAlert } from '../state/page';

export default function LoginCollation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const kycImage = useSelector(kycImageSelector);
    const token = useSelector(accountTokenSelector);

    const handleShoot = async image => {
        const res = await post({
            url: API.kyc,
            config: { headers: { token } },
            query: { kycType: KYC_TYPE.LOGIN },
            data: {
                registeredImage: dataURLtoFile(kycImage, 'registeredImage'),
                collationImage: dataURLtoFile(image, 'collationImage'),
            },
        });
        if (res) {
            const { msg, result } = res;
            if (result) {
                const {
                    rawData: { admin, ca },
                } = result;
                const { bcInput: adminBcInput } = admin;
                const { result: adminResult, contractId, authId } = adminBcInput;
                const caResults = [];
                if (ca) {
                    const caKey = Object.keys(ca);
                    for (let index = 0; index < caKey.length; index++) {
                        const caItem = ca[caKey[index]];
                        caResults.push({
                            caBcUserId: caKey[index],
                            caResult: caItem.bcInput.result,
                        });
                    }
                }
                dispatch(
                    setKycResultHistory({
                        adminResult,
                        contractId,
                        authId,
                        caResults,
                        date: new Date().getTime(),
                        isSeen: false,
                        kycType: KYC_TYPE.LOGIN,
                    }),
                );
            }
            dispatch(setSuccessAlert('送信しました'));
            setTimeout(() => {
                dispatch(closeAlert());
                navigate(LOGIN_HISTORY_ANIMATION_PATH);
            }, 750);
        }
    };

    return (
        <>
            <FaceShooting hasRegBtn onBack={() => navigate(INDEX_PATH)} onShoot={handleShoot} />
        </>
    );
}
