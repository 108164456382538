import stampedIcon from '../../assets/images/stamped.png';
import { StampedBox } from '../../styles/style';

export default function Stamped({ x, y }) {
    return (
        <>
            <StampedBox component="img" src={stampedIcon} alt="stamped-icon" sx={{ left: x || 0, top: y || 0 }} />
        </>
    );
}
