import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, useTheme } from '@mui/material';
import { TopMessageContainer } from '../../styles/style';

export default function TopMessage({ hasHeader = true, severity = 'success', message }) {
    const theme = useTheme();
    return (
        <>
            <TopMessageContainer hasHeader={hasHeader} severity={severity}>
                <Box sx={{ width: theme.spacing(3.25), height: theme.spacing(3.25), mr: theme.spacing(0.5) }}>
                    {severity === 'success' ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
                </Box>
                <Box>{message}</Box>
            </TopMessageContainer>
        </>
    );
}
