import axiosBase from 'axios';
import { API, ERROR_MESSAGE } from '../constants/common';
import { LOGIN_PATH } from '../router/routerPath';
import { USER_EXPIRED } from '../state/account/constants';
import { setAppError } from '../state/app';
import { setWarningAlert } from '../state/page';
import { store } from '../state/store';

const initRequest = () => {
    const headers = {
        'content-type': 'application/x-www-form-urlencoded',
        accept: 'application/json',
        'accept-language': 'en-GB,en;q=0.9,vi-VN;q=0.8,vi;q=0.7,en-US;q=0.6,ja;q=0.5;jp;',
    };

    const axios = axiosBase.create({
        baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
        withCredentials: false,
        headers,
    });

    axios.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            let getMessage = data => {
                const { message, detail } = data;
                return message || detail.msg || (detail[0] && detail[0].msg) || '';
            };
            let displayNotification = message => {
                let defaultMessage = ERROR_MESSAGE.DEFAULT_ERROR;
                store.dispatch(setWarningAlert(message || defaultMessage));
            };
            if (error.code === 'ECONNABORTED') {
                displayNotification();
            } else if (error.response) {
                const { status, data, config } = error.response;
                if (status === 491) {
                    store.dispatch({ type: USER_EXPIRED });
                    window.location = LOGIN_PATH;
                } else if (status === 401) {
                    const exceptionUrl = [API.login];
                    if (config && config.url && exceptionUrl.includes(config.url)) {
                        displayNotification(getMessage(data));
                    } else {
                        store.dispatch(setAppError(getMessage(data) || '不正なエラー。ログインし直してください。'));
                    }
                } else if (data) {
                    const msg = getMessage(data);
                    if (msg) {
                        displayNotification(msg);
                    }
                }
            }

            return Promise.reject(error.response);
        },
    );

    return axios;
};

const generateUrl = (url, params) => {
    let genUrl = url;
    if (params) {
        const keys = Object.keys(params);
        if (keys.length > 0) {
            for (const key of keys) {
                genUrl = genUrl.replace(`{${key}}`, params[key]);
            }
        }
    }
    return genUrl;
};

const generateQueryString = query => {
    if (query && typeof query === 'object') {
        const keys = Object.keys(query);
        if (keys.length > 0) {
            const qString = [];
            for (const key of keys) {
                qString.push(`${key}=${query[key]}`);
            }
            return `?${qString.join('&')}`;
        }
    }
    return '';
};

const request = async (callback, reqOption) => {
    try {
        const { url, params, authenticate } = reqOption;
        const genUrl = generateUrl(url, params);
        const axios = initRequest(authenticate);
        const response = await callback(axios, genUrl);
        return response.data;
    } catch (error) {
        console.warn('▼Api Error▼', error);
        return undefined;
    }
};

export const get = async reqOption => {
    return await request((axios, genUrl) => {
        return axios.get(`${genUrl}${generateQueryString(reqOption.query)}`, reqOption.config);
    }, reqOption);
};

const _getFormData = data => {
    if (data && Object.keys(data).length > 0) {
        const formData = new FormData();
        const key = Object.keys(data);
        for (let i = 0; i < key.length; i++) {
            formData.append(key[i], data[key[i]]);
        }
        return formData;
    }
    return null;
};

export const post = async reqOption => {
    return await request((axios, genUrl) => {
        return axios.post(
            `${genUrl}${generateQueryString(reqOption.query)}`,
            _getFormData(reqOption.data),
            reqOption.config,
        );
    }, reqOption);
};

export const put = async reqOption => {
    return await request((axios, genUrl) => {
        return axios.put(genUrl, _getFormData(reqOption.data), reqOption.config);
    }, reqOption);
};

export const patch = async reqOption => {
    return await request((axios, genUrl) => {
        return axios.patch(genUrl, _getFormData(reqOption.data), reqOption.config);
    }, reqOption);
};

export const deleteMethod = async reqOption => {
    return await request((axios, genUrl) => {
        return axios.delete(genUrl, reqOption.config);
    }, reqOption);
};
