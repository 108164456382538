import { getDefaultState } from '../state';
import { SET_LOADING, SET_NOTIFICATION, SET_IS_SHOOTING, SET_ALERT, CLOSE_ALERT } from './constants';

export const setLoading = loading => ({
    type: SET_LOADING,
    loading,
});
export const setNotification = notification => ({
    type: SET_NOTIFICATION,
    notification,
});
export const setIsShooting = isShooting => ({
    type: SET_IS_SHOOTING,
    isShooting,
});

export const setAlert = ({ severity, message }) => ({
    type: SET_ALERT,
    alert: { isOpen: true, severity, message },
});

export const setWarningAlert = message => setAlert({ severity: 'warning', message });
export const setSuccessAlert = message => setAlert({ severity: 'success', message });

export const closeAlert = () => ({
    type: CLOSE_ALERT,
});

export default function reducer(state = getDefaultState().page, action) {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.loading };
        case SET_NOTIFICATION:
            return { ...state, notification: action.notification };
        case SET_IS_SHOOTING:
            return { ...state, isShooting: action.isShooting };
        case SET_ALERT:
            return { ...state, alert: action.alert };
        case CLOSE_ALERT:
            return { ...state, alert: { ...state.alert, isOpen: false } };
        default:
            return state;
    }
}

export const loadingSelector = state => state.page.loading;
export const notificationSelector = state => state.page.notification;
export const isShootingSelector = state => state.page.isShooting;
export const alertSelector = state => state.page.alert;
