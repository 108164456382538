import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FaceShooting from '../components/organisms/FaceShooting';
import { API, KYC_TYPE } from '../constants/common';
import { dataURLtoFile } from '../helpers/common';
import { CONTRACT_PREVIEW_PATH, HISTORY_ANIMATION_PATH } from '../router/routerPath';
import { post } from '../services/request';
import { accountTokenSelector } from '../state/account';
import { kycImageSelector, setAuthId, setContractId, setKycResultHistory } from '../state/app';
import { closeAlert, setSuccessAlert } from '../state/page';

export default function Collation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const kycImage = useSelector(kycImageSelector);
    const token = useSelector(accountTokenSelector);

    const handleShoot = async image => {
        const res = await post({
            url: API.kyc,
            config: { headers: { token } },
            query: { kycType: KYC_TYPE.COLLATION },
            data: {
                registeredImage: dataURLtoFile(kycImage, 'registeredImage'),
                collationImage: dataURLtoFile(image, 'collationImage'),
            },
        });
        if (res) {
            const { msg, result, authId: resAuthId, contractId: resContractId } = res;
            if (result) {
                const {
                    rawData: { admin, ca },
                } = result;
                const { bcInput: adminBcInput } = admin;
                const { result: adminResult, contractId, authId } = adminBcInput;
                const caResults = [];
                if (ca) {
                    const caKey = Object.keys(ca);
                    for (let index = 0; index < caKey.length; index++) {
                        const caItem = ca[caKey[index]];
                        caResults.push({
                            caBcUserId: caKey[index],
                            caResult: caItem.bcInput.result,
                        });
                    }
                }
                dispatch(
                    setKycResultHistory({
                        adminResult,
                        contractId,
                        authId,
                        caResults,
                        date: new Date().getTime(),
                        isSeen: false,
                        kycType: KYC_TYPE.COLLATION,
                    }),
                );
            }
            if (result.err || result.errCode) {
                dispatch(setAuthId(''));
                dispatch(setContractId(''));
            } else {
                dispatch(setAuthId(resAuthId));
                dispatch(setContractId(resContractId));
            }
            dispatch(setSuccessAlert('送信しました'));
            setTimeout(() => {
                dispatch(closeAlert());
                navigate(HISTORY_ANIMATION_PATH);
            }, 750);
        }
    };

    return (
        <>
            <FaceShooting onBack={() => navigate(CONTRACT_PREVIEW_PATH)} onShoot={handleShoot} />
        </>
    );
}
