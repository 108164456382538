import {
    SET_CHECKPOINT,
    SET_APP_ERROR,
    SET_KYC_IMAGE,
    SET_KYC_RESULT_HISTORY,
    SET_STAMPED_POSITION,
    SEEN_KYC_RESULT_HISTORY,
    RESET_KYC_RESULT,
    SET_AUTH_ID,
    SET_CONTRACT_ID,
} from './constants';
import { getDefaultState } from '../state';
import { USER_EXPIRED } from '../account/constants';

export const setCheckpoint = checkpoint => ({
    type: SET_CHECKPOINT,
    checkpoint,
});

export const setAppError = appError => ({
    type: SET_APP_ERROR,
    appError,
});

export const setKycImage = kycImage => ({
    type: SET_KYC_IMAGE,
    kycImage,
});

export const setKycResultHistory = kycResult => ({
    type: SET_KYC_RESULT_HISTORY,
    kycResult,
});

export const seenKycResultHistory = () => ({
    type: SEEN_KYC_RESULT_HISTORY,
});

export const resetKycResult = () => ({
    type: RESET_KYC_RESULT,
});

export const setStampedPosition = stampedPosition => ({
    type: SET_STAMPED_POSITION,
    stampedPosition,
});

export const setAuthId = authId => ({
    type: SET_AUTH_ID,
    authId,
});

export const setContractId = contractId => ({
    type: SET_CONTRACT_ID,
    contractId,
});

export default function reducer(state = getDefaultState().app, action) {
    switch (action.type) {
        case SET_CHECKPOINT:
            return { ...state, checkpoint: action.checkpoint };
        case SET_APP_ERROR:
            return { ...state, appError: action.appError };
        case SET_KYC_IMAGE:
            return { ...state, kycImage: action.kycImage };
        case SET_KYC_RESULT_HISTORY:
            return {
                ...state,
                kycResultHistory: [action.kycResult, ...(state.kycResultHistory || [])],
            };
        case SEEN_KYC_RESULT_HISTORY:
            return {
                ...state,
                kycResultHistory: (state.kycResultHistory || []).map(kycResult => {
                    return { ...kycResult, isSeen: true };
                }),
            };
        case RESET_KYC_RESULT:
            return { ...state, kycResultHistory: [], stampedPosition: null, authId: '', contractId: '' };
        case SET_STAMPED_POSITION:
            return { ...state, stampedPosition: action.stampedPosition };
        case SET_AUTH_ID:
            return { ...state, authId: action.authId };
        case SET_CONTRACT_ID:
            return { ...state, contractId: action.contractId };
        case USER_EXPIRED:
            return {
                checkpoint: '',
                appError: '',
                kycImage: null,
                kycResultHistory: [],
                stampedPosition: null,
                authId: '',
                contractId: '',
            };
        default:
            return state;
    }
}

export const appErrorSelector = state => state.app.appError;
export const kycImageSelector = state => state.app.kycImage;
export const kycResultHistorySelector = state => (state.app.kycResultHistory || []).sort((a, b) => b.date - a.date);
export const stampedPositionSelector = state => state.app.stampedPosition;
export const authIdSelector = state => state.app.authId;
export const contractIdSelector = state => state.app.contractId;
