import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HistoryAnimate from '../components/organisms/HistoryAnimate';
import { KYC_TYPE } from '../constants/common';
import { CONTRACT_PREVIEW_PATH, LOGIN_COLLATION_PATH } from '../router/routerPath';
import { kycResultHistorySelector, seenKycResultHistory } from '../state/app';

export default function LoginHistoryAnimation() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const kycResultHistory = useSelector(kycResultHistorySelector);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (kycResultHistory && kycResultHistory.length > 0) {
            const resultLogin = kycResultHistory.filter(kycResult => (kycResult.kycType = KYC_TYPE.LOGIN));
            if (resultLogin && resultLogin.length > 0) {
                setIsSuccess(resultLogin[0].adminResult);
            }
        }
        return () => {
            dispatch(seenKycResultHistory());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hanleNext = () => {
        navigate(isSuccess ? CONTRACT_PREVIEW_PATH : LOGIN_COLLATION_PATH);
    };

    const handleBack = () => {
        navigate(LOGIN_COLLATION_PATH);
    };

    return (
        <>
            <HistoryAnimate
                hasRegBtn
                kycResultHistory={kycResultHistory}
                animate
                onBack={handleBack}
                onNext={hanleNext}
                nextBtnText={isSuccess ? '次へ' : '再撮影'}
            />
        </>
    );
}
