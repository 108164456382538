import { format } from 'date-fns';
import { FORMAT } from '../constants/common';

export const sleep = timeout => {
    return new Promise(resolve => {
        setTimeout(resolve, timeout || 1000);
    });
};

export const dataURLtoFile = (dataurl, filename) => {
    if (!dataurl) {
        return new File([], filename, { type: 'image/png' });
    }
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1;
    }
    return new File([u8arr], filename, { type: mime });
};

export const fullDate = date => {
    return format(new Date(date || ''), FORMAT.FULLDATE);
};

export const getContractText = text => {
    return text.replace(':contractDate', format(new Date(), FORMAT.DATE));
};

export const getMousePos = (e, $this) => {
    var m_posx = 0,
        m_posy = 0,
        e_posx = 0,
        e_posy = 0,
        obj = $this;
    if (!e) {
        e = window.event;
    }
    if (e.pageX || e.pageY) {
        m_posx = e.pageX;
        m_posy = e.pageY;
    } else if (e.clientX || e.clientY) {
        m_posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        m_posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    const scrollTop = obj.scrollTop;
    const scrollLeft = obj.scrollLeft;
    if (obj.offsetParent) {
        do {
            e_posx += obj.offsetLeft;
            e_posy += obj.offsetTop;
        } while ((obj = obj.offsetParent));
    }
    return {
        positionX: m_posx - e_posx + scrollLeft,
        positionY: m_posy - e_posy + scrollTop,
    };
};
