import { useEffect } from 'react';

const useVhVw = () => {
    useEffect(() => {
        const detectVh = () => {
            const mainContainer = document.querySelector('.main-container');
            const cw = mainContainer ? mainContainer.clientWidth * 0.01 : 0;
            let vh = window.innerHeight * 0.01;
            let vw = window.innerWidth * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            document.documentElement.style.setProperty('--vw', `${cw || vw}px`);
        };
        detectVh();
        window.addEventListener('resize', detectVh);
        return () => {
            window.removeEventListener('resize', detectVh);
        };
    }, []);
};

export default useVhVw;
