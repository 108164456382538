import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HistoryAnimate from '../components/organisms/HistoryAnimate';
import { STAMPED_CONTRACT_PATH } from '../router/routerPath';
import { kycResultHistorySelector, stampedPositionSelector } from '../state/app';

export default function History() {
    const navigate = useNavigate();
    const kycResultHistory = useSelector(kycResultHistorySelector);
    const stampedPosition = useSelector(stampedPositionSelector);

    const handleBack = () => {
        navigate(STAMPED_CONTRACT_PATH);
    };

    return (
        <>
            <HistoryAnimate
                message={stampedPosition ? '契約書に押印しました' : ''}
                kycResultHistory={kycResultHistory}
                onBack={handleBack}
                onNext={handleBack}
                nextBtnText="契約書を確認"
            />
        </>
    );
}
