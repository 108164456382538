import { LineHorizontal, LineVertical, StampContainer, stampHalfSize } from '../../styles/style';

export default function StampPosition({ x, y }) {
    return (
        <>
            <LineVertical sx={{ left: `calc(${x} + ${stampHalfSize}px)` || 0 }} />
            <LineHorizontal sx={{ top: `calc(${y} + ${stampHalfSize}px)` || 0 }} />
            <StampContainer sx={{ left: x || 0, top: y || 0 }} />
        </>
    );
}
