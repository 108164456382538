import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FormButton from '../components/atom/FormButton';
import FormTextField from '../components/atom/FormTextField';
import Header from '../components/molecules/Header';
import { API } from '../constants/common';
import validator from '../helpers/validator';
import { INDEX_PATH } from '../router/routerPath';
import { post } from '../services/request';
import { setAccountToken } from '../state/account';
import { resetKycResult } from '../state/app';
import { closeAlert, setLoading, setSuccessAlert } from '../state/page';
import { headerHeight, PageContainer, RelativeBox } from '../styles/style';

const schema = validator.object().shape({
    userId: validator.string().required('ユーザーIDを入力してください'),
    password: validator.string().required('パスワードを入力してください'),
});

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const userIdParam = searchParams.get('userId');
    const passwordParam = searchParams.get('password');

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (userIdParam && passwordParam) {
            setValue('userId', userIdParam);
            setValue('password', passwordParam);
            handleSubmit(onFormSubmit)();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFormSubmit = async data => {
        dispatch(setLoading(true));
        const res = await post({
            url: API.login,
            data: {
                userId: data.userId,
                password: data.password,
            },
        });
        dispatch(setLoading(false));
        if (res) {
            dispatch(setAccountToken(res.token));
            dispatch(resetKycResult());
            dispatch(setSuccessAlert(res.msg || 'ログインに成功しました'));
            setTimeout(() => {
                dispatch(closeAlert());
                navigate(INDEX_PATH);
            }, 750);
        }
    };

    return (
        <>
            <Header hasBack={false} hasRegBtn />
            <PageContainer centerFlex hasHeader>
                <RelativeBox
                    component="form"
                    onSubmit={handleSubmit(onFormSubmit)}
                    noValuserIdate
                    sx={{ mb: `${headerHeight}px` }}>
                    <FormTextField
                        name="userId"
                        placeholder="ユーザーID"
                        control={control}
                        error={!!errors.userId}
                        helperText={errors.userId ? errors.userId.message || '' : ''}
                    />
                    <FormTextField
                        name="password"
                        placeholder="パスワード"
                        control={control}
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message || '' : ''}
                    />
                    <FormButton type="submit">ログイン</FormButton>
                </RelativeBox>
            </PageContainer>
        </>
    );
}
