import { useNavigate } from 'react-router-dom';
import contractContent from '../assets/images/contract.png';
import FormButton from '../components/atom/FormButton';
import Footer from '../components/molecules/Footer';
import GuideDialog from '../components/molecules/GuideDialog';
import Header from '../components/molecules/Header';
import { COLLATION_PATH, LOGIN_HISTORY_ANIMATION_PATH } from '../router/routerPath';
import { ContractContainer, ContractContent, PageContainer, PageContent } from '../styles/style';

export default function ContractPreview() {
    const navigate = useNavigate();
    const hanleCollate = () => {
        navigate(COLLATION_PATH);
    };
    const handleBack = () => {
        navigate(LOGIN_HISTORY_ANIMATION_PATH);
    };
    return (
        <>
            <Header onBack={handleBack} hasRegBtn />
            <PageContainer hasHeader paddingSm>
                <GuideDialog message="契約書の内容を確認してください。" />
                <PageContent hasGuide hasFooter>
                    <ContractContainer>
                        <ContractContent component="img" src={contractContent}></ContractContent>
                    </ContractContainer>
                </PageContent>
                <Footer>
                    <FormButton onClick={hanleCollate}>照合する</FormButton>
                </Footer>
            </PageContainer>
        </>
    );
}
