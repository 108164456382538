import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import appLogoExpoW from '../../assets/images/app-logo-expo-w.png';
import appLogoExpo from '../../assets/images/app-logo-expo.png';
import settingIcon from '../../assets/images/setting.png';
import { INDEX_PATH, REGISTRATION_PATH } from '../../router/routerPath';
import { FlexBox, HeaderButton, HeaderContainer } from '../../styles/style';

export default function Header({ hasBack = true, hasLogo = true, hasRegBtn = false, onBack, white = true, black }) {
    const navigate = useNavigate();

    const handleBack = () => {
        if (onBack) {
            onBack();
        } else {
            navigate(-1);
        }
    };
    return (
        <HeaderContainer white={white} black={black}>
            <FlexBox justifyContent="flex-start" width={70}>
                {hasBack && (
                    <HeaderButton variant="text" onClick={handleBack} black={black ? 1 : 0} sx={{ width: '66px' }}>
                        <ChevronLeftIcon sx={{ ml: '-5px' }} />
                        戻る
                    </HeaderButton>
                )}
            </FlexBox>
            {hasLogo && (
                <FlexBox sx={{ flex: 1 }}>
                    <Box
                        component="img"
                        src={black ? appLogoExpoW : appLogoExpo}
                        alt="app-logo"
                        sx={{ height: '40px', cursor: 'pointer' }}
                        onClick={() => navigate(INDEX_PATH)}
                    />
                </FlexBox>
            )}
            <FlexBox justifyContent="flex-end" width={70}>
                {hasRegBtn && (
                    <Box
                        component="img"
                        src={settingIcon}
                        alt="app-logo"
                        onClick={() => navigate(REGISTRATION_PATH)}
                        sx={{ height: '40px' }}
                    />
                )}
            </FlexBox>
        </HeaderContainer>
    );
}
