import { Box, Button, Fab, styled } from '@mui/material';

export const headerHeight = 50;
export const topMessageHeight = 40;
export const formFieldHeight = 50;
export const flashTimout = 200;
export const guideDialogHeight = 100;
export const footerHeight = 80;
export const stampSize = 120;
export const stampHalfSize = stampSize / 2;

export const boxShadow =
    '0px 2px 4px -1px rgb(0 0 0 / 15%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 2px 4px 2px rgb(0 0 0 / 15%)';

export const HeaderContainer = styled(Box)(({ theme, white, black }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: headerHeight,
    ...(white && {
        background: '#fff',
        boxShadow,
    }),
    ...(black && {
        background: '#000',
    }),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '@media screen and (max-width: 320px)': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    '@media screen and (max-width: 280px)': {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    zIndex: 1,
}));

export const HeaderButton = styled(Button)(({ theme, black }) => ({
    position: 'absolute',
    fontSize: '1.25rem',
    height: '40px',
    paddingLeft: '0px',
    paddingRight: '0px',
    ...(black && { color: '#fff' }),
}));

export const TopMessageContainer = styled(Box)(({ theme, hasHeader, severity }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: hasHeader ? `${headerHeight}px` : 0,
    left: 0,
    width: '100%',
    height: topMessageHeight,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '@media screen and (max-width: 320px)': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    '@media screen and (max-width: 280px)': {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    boxShadow,
    fontSize: '1.125rem',
    fontWeight: 'bold',
    backgroundColor:
        severity === 'success'
            ? theme.palette.success.light
            : severity === 'error'
            ? theme.palette.error.light
            : theme.palette.info.light,
    color: '#fff',
    zIndex: 2,
}));

export const PageContainer = styled(Box)(({ theme, hasHeader, hasTop, centerFlex, noPadding, center, paddingSm }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: hasHeader ? `${hasTop ? headerHeight + topMessageHeight : headerHeight}px` : 0,
    ...(!noPadding && {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(paddingSm ? 1 : 2),
        paddingRight: theme.spacing(paddingSm ? 1 : 2),
    }),

    height: `calc(var(--vh, 1vh) * 100 ${
        hasHeader ? `- ${center ? headerHeight * 2 : hasTop ? headerHeight + topMessageHeight : headerHeight}px` : ''
    })`,
    ...(centerFlex && {
        justifyContent: 'center',
        alignItems: 'center',
    }),
}));

export const PageContent = styled(Box)(({ theme, hasGuide, hasFooter, flex, animate }) => ({
    width: '100%',
    position: 'relative',
    overflowY: 'auto',
    ...(hasGuide && {
        paddingTop: `${guideDialogHeight}px`,
    }),
    ...(hasFooter && {
        paddingBottom: `${footerHeight}px`,
    }),
    ...(flex && {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    }),
    ...(animate && {
        animation: 'FadeIn 2000ms',
    }),
}));

export const RelativeBox = styled(Box)(({ theme }) => ({
    width: '100%',
    position: 'relative',
}));

export const FlexBox = styled(Box)(({ theme, width, justifyContent }) => ({
    display: 'flex',
    justifyContent: justifyContent || 'center',
    alignItems: 'center',
    ...(width && {
        width: `${width || 0}px`,
        position: 'relative',
    }),
}));

export const AbsoluteFlexBox = styled(FlexBox)(({ theme }) => ({
    width: '100%',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '100%',
}));

export const FaceFrame = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
        width: '40%',
    },
}));

export const ShootButton = styled(Fab)(({ theme }) => ({
    position: 'absolute',
    bottom: '-20px',
    width: '100px',
    height: '100px',
    border: '2px solid #fff',
    color: '#fff',
    fontSize: '1.25rem',
    boxShadow: `0 0 0 10px ${theme.palette.primary.main} !important`,
}));

export const ShootMask = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '80px',
    backgroundColor: 'rgba(0,0,0,.85)',
}));

export const Flash = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    opacity: 0.3,
    transition: `opacity ${flashTimout}ms, transform ${flashTimout}ms`,
}));

export const GuideDialogContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: `${guideDialogHeight}px`,
    backgroundColor: '#000',
    color: '#fff',
    opacity: 0.85,
    zIndex: 2,
    transition: 'all 500ms',
    wordBreak: 'break-all',
    overflowY: 'auto',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    padding: theme.spacing(2),
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: `${footerHeight}px`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: 1,
    transition: 'all 500ms',
    overflow: 'hidden',
    boxShadow,
    '& button:not(:last-child)': {
        marginRight: theme.spacing(2),
    },
}));

export const ContractContainer = styled(Box)(({ theme, sign }) => ({
    position: 'relative',
    background: '#fff',
    width: '100%',
    ...(sign && {
        userSelect: 'none',
    }),
    overflow: 'hidden',
}));

export const ContractContent = styled(Box)(({ theme, sign }) => ({
    width: '100%',
}));

export const ListContainer = styled(Box)(({ theme, animate }) => ({
    background: '#fff',
    width: '100%',
    paddingTop: theme.spacing(1),
}));

export const CardContainer = styled(Box)(({ theme }) => ({
    background: '#fff',
    width: '100%',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    wordBreak: 'break-all',
}));

export const KycResult = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const KycResultInfo = styled(Box)(({ theme }) => ({
    flex: '1',
    marginLeft: theme.spacing(1),
}));

export const DateTime = styled(Box)(({ theme }) => ({
    textAlign: 'right',
    color: theme.palette.grey[500],
}));

export const LineVertical = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    top: 0,
    width: '1px',
    minHeight: '100%',
    background: theme.palette.primary.main,
}));

export const LineHorizontal = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '1px',
    background: theme.palette.primary.main,
}));

export const StampContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    width: `${stampSize}px`,
    height: `${stampSize}px`,
    background: 'transparent',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: '50%',
}));

export const StampedBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    width: `${stampSize}px`,
    height: `${stampSize}px`,
    background: 'transparent',
}));
