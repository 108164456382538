import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import contractContent from '../assets/images/contract.png';
import FormButton from '../components/atom/FormButton';
import Footer from '../components/molecules/Footer';
import GuideDialog from '../components/molecules/GuideDialog';
import Header from '../components/molecules/Header';
import StampPosition from '../components/molecules/StampPosition';
import { API } from '../constants/common';
import { getMousePos } from '../helpers/common';
import { COLLATION_PATH, STAMPED_CONTRACT_PATH, STAMP_PATH } from '../router/routerPath';
import { post } from '../services/request';
import { accountTokenSelector } from '../state/account';
import { authIdSelector, contractIdSelector, setStampedPosition } from '../state/app';
import { closeAlert, setLoading, setSuccessAlert, setWarningAlert } from '../state/page';
import {
    ContractContainer,
    ContractContent,
    PageContainer,
    PageContent,
    stampHalfSize,
    stampSize,
} from '../styles/style';

export default function Contract() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(accountTokenSelector);
    const authId = useSelector(authIdSelector);
    const contractId = useSelector(contractIdSelector);
    const containRef = useRef(null);
    const contractRef = useRef(null);
    const [isSigning, setIsSigning] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (!(token && authId && contractId)) {
            navigate(COLLATION_PATH);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickContract = event => {
        const elm = containRef.current;
        const { positionX, positionY } = getMousePos(event, elm);
        const { offsetWidth, offsetHeight } = contractRef.current;
        let x = positionX - stampHalfSize;
        let y = positionY - (100 + stampHalfSize);
        const maxX = offsetWidth - (stampSize + 2);
        const maxY = offsetHeight - stampSize;
        if (x < 0) {
            x = 0;
        } else if (x > maxX) {
            x = maxX;
        }
        if (y < 0) {
            y = 0;
        } else if (y > maxY) {
            y = maxY;
        }
        setMousePosition({ x: `${(x * 100) / offsetWidth}%`, y: `${(y * 100) / offsetHeight}%` });
        setIsSigning(true);
    };

    const hanleSign = async () => {
        dispatch(setLoading(true));
        const res = await post({
            url: API.signature,
            config: { headers: { token } },
            query: { authId, contractId },
        });
        dispatch(setLoading(false));
        if (res) {
            const { msg, result } = res;
            if (result) {
                dispatch(setSuccessAlert(msg || '押印しました'));
                dispatch(setStampedPosition(mousePosition));
                setTimeout(() => {
                    dispatch(closeAlert());
                    navigate(STAMPED_CONTRACT_PATH);
                }, 750);
            } else {
                dispatch(setWarningAlert(msg || '押印に失敗しました'));
            }
        }
    };
    return (
        <>
            <Header onBack={() => navigate(STAMP_PATH)} />
            <PageContainer hasHeader paddingSm>
                <GuideDialog message="押印したい箇所をタップしてください。" />
                <PageContent hasGuide hasFooter={isSigning} ref={containRef}>
                    <ContractContainer sign onClick={handleClickContract} ref={contractRef}>
                        <ContractContent component="img" src={contractContent}></ContractContent>
                        {isSigning && <StampPosition x={mousePosition.x} y={mousePosition.y} />}
                    </ContractContainer>
                </PageContent>
                {isSigning && (
                    <Footer>
                        <FormButton onClick={hanleSign}>押印</FormButton>
                    </Footer>
                )}
            </PageContainer>
        </>
    );
}
