import { Navigate, Route, Routes } from 'react-router-dom';
import routes from './routerConfig';
import { INDEX_PATH } from './routerPath';
import SecurityRoute from './SecurityRoute';

export default function AppRoute() {
    return (
        <Routes>
            {routes.map((route, i) => (
                <Route key={i} path={route.path} element={<SecurityRoute {...route} />} />
            ))}
            <Route path="*" element={<Navigate to={INDEX_PATH} />} />
        </Routes>
    );
}
