export const getDefaultState = () => ({
    account: {
        accountId: '',
        token: '',
    },
    app: {
        checkpoint: '',
        appError: '',
        kycImage: null,
        kycResultHistory: [],
        stampedPosition: null,
        authId: '',
        contractId: '',
    },
    page: {
        loading: false,
        notification: '',
        isShooting: false,
        alert: {
            isOpen: false,
            severity: '',
            message: '',
        },
    },
});
