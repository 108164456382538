import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import stampIcon from '../assets/images/stamp.png';
import FormButton from '../components/atom/FormButton';
import Footer from '../components/molecules/Footer';
import Header from '../components/molecules/Header';
import { CONTRACT_PATH, HISTORY_ANIMATION_PATH } from '../router/routerPath';
import { PageContainer, PageContent } from '../styles/style';

export default function Stamp() {
    const navigate = useNavigate();
    const theme = useTheme();

    const handleBack = () => {
        navigate(HISTORY_ANIMATION_PATH);
    };
    const handleNext = () => {
        navigate(CONTRACT_PATH);
    };
    return (
        <>
            <Header onBack={handleBack} />
            <PageContainer centerFlex hasHeader>
                <PageContent flex hasFooter animate>
                    <Box component="img" src={stampIcon} alt="stamp-icon" sx={{ width: '40%', margin: 'auto' }} />
                    <Box sx={{ fontSize: '1.25rem', my: theme.spacing(2) }}>電子実印が利用可能になりました</Box>
                </PageContent>
                <Footer>
                    <FormButton onClick={handleBack}>履歴を確認</FormButton>
                    <FormButton onClick={handleNext}>次へ</FormButton>
                </Footer>
            </PageContainer>
        </>
    );
}
