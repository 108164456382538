import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import kycFaceIcon from '../../assets/images/kyc_face.png';
import Header from '../../components/molecules/Header';
import { setIsShooting } from '../../state/page';
import { FaceFrame, PageContainer, ShootButton, ShootMask } from '../../styles/style';
import GuideDialog from '../molecules/GuideDialog';
import ShootFlash from '../molecules/ShootFlash';

export default function FaceShooting({ onBack, onShoot, hasRegBtn }) {
    const dispatch = useDispatch();
    const webcamRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isReShooting, setIsReShooting] = useState(false);
    const [isHaveCamera, setIsHaveCamera] = useState(true);
    const [kycImage, setKycImage] = useState(null);

    const handleCameraError = event => {
        setIsHaveCamera(false);
    };

    const handleRefreshDevice = () => {
        setIsHaveCamera(webcamRef.current.state.hasUserMedia);
        window.location.reload();
    };

    const handleShoot = async () => {
        if (isLoading) {
            return;
        }
        const kycImage = webcamRef.current.getScreenshot();
        if (kycImage) {
            dispatch(setIsShooting(true));
            setIsLoading(true);
            setKycImage(kycImage);
            if (onShoot) {
                await onShoot(kycImage);
            }
            setIsLoading(false);
            setIsReShooting(true);
        }
    };

    return (
        <>
            <Header onBack={onBack} hasRegBtn={hasRegBtn} black />
            <Dialog open={!isHaveCamera} maxWidth="xs" onClose={handleRefreshDevice}>
                <DialogContent>お使いのデバイスのカメラは検出できません。 再確認して再試行してください。</DialogContent>
            </Dialog>
            <PageContainer centerFlex hasHeader noPadding center>
                <GuideDialog message="顔を正面に向けて撮影してください。" />
                {isLoading ? (
                    <img src={kycImage} alt="face-shooting" />
                ) : (
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        width="100%"
                        screenshotFormat="image/jpeg"
                        onUserMediaError={handleCameraError}
                        mirrored={true}
                        videoConstraints={{
                            facingMode: 'user',
                        }}
                    />
                )}
                <FaceFrame>
                    <Box component="img" src={kycFaceIcon} alt="kyc-face" />
                </FaceFrame>
                <ShootButton color="primary" onClick={handleShoot}>
                    {isLoading ? <CircularProgress color="inherit" size="3rem" /> : isReShooting ? '再撮影' : '撮影'}
                </ShootButton>
            </PageContainer>
            <ShootMask />
            <ShootFlash />
        </>
    );
}
