import { Alert, Dialog } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertSelector, closeAlert } from '../../state/page';

export default function AlertDialog() {
    const dispatch = useDispatch();
    const alert = useSelector(alertSelector);

    const handleClose = () => {
        dispatch(closeAlert());
    };

    useEffect(() => {
        if (alert.isOpen) {
            setTimeout(() => {
                handleClose();
            }, 4000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert.isOpen]);

    return (
        <Dialog open={alert.isOpen} onClose={handleClose} maxWidth="sm">
            <Alert severity={alert.severity || 'info'}>
                <div dangerouslySetInnerHTML={{ __html: alert.message }}></div>
            </Alert>
        </Dialog>
    );
}
