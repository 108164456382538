import { getDefaultState } from '../state';
import { SET_ACCOUNT_ID, SET_ACCOUNT_TOKEN, USER_EXPIRED } from './constants';

export const setAccountId = accountId => ({
    type: SET_ACCOUNT_ID,
});

export const setAccountToken = token => ({
    type: SET_ACCOUNT_TOKEN,
    token,
});

export default function reducer(state = getDefaultState().account, action) {
    switch (action.type) {
        case SET_ACCOUNT_ID:
            return {
                ...state,
                accountId: action.accountId,
            };
        case SET_ACCOUNT_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case USER_EXPIRED:
            return {};

        default:
            return state;
    }
}

export const accountIdSelector = state => state.account.accountId;
export const accountTokenSelector = state => state.account.token;
