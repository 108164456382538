export const INDEX_PATH = '/';
export const REGISTRATION_PATH = '/registration';
export const LOGIN_PATH = '/login';
export const LOGIN_COLLATION_PATH = '/login-collation';
export const LOGIN_HISTORY_ANIMATION_PATH = '/login-history-animation';
export const CONTRACT_PREVIEW_PATH = '/contract-preview';
export const COLLATION_PATH = '/collation';
export const HISTORY_ANIMATION_PATH = '/history-animation';
export const STAMP_PATH = '/stamp';
export const CONTRACT_PATH = '/contract';
export const STAMPED_CONTRACT_PATH = '/stamped-contract';
export const HISTORY_PATH = '/history';
