import { fullDate } from '../../helpers/common';
import { CardContainer, DateTime, ListContainer, PageContainer, PageContent } from '../../styles/style';
import FormButton from '../atom/FormButton';
import Footer from '../molecules/Footer';
import Header from '../molecules/Header';
import KycResultAnimate from '../molecules/KycResultAnimate';
import NoData from '../molecules/NoData';
import TopMessage from '../molecules/TopMessage';

export default function HistoryAnimate({ message, kycResultHistory, animate, onBack, onNext, nextBtnText, hasRegBtn }) {
    return (
        <>
            <Header onBack={onBack} hasRegBtn={hasRegBtn} />
            {message && <TopMessage message={message} />}
            <PageContainer hasHeader noPadding hasTop={!!message}>
                <PageContent hasFooter>
                    {kycResultHistory && kycResultHistory.length > 0 ? (
                        <ListContainer>
                            {kycResultHistory.map((kycResult, idx) => (
                                <CardContainer key={idx}>
                                    <KycResultAnimate kycResult={kycResult} animate={animate && !kycResult.isSeen} />
                                    <DateTime>{fullDate(kycResult.date)}</DateTime>
                                </CardContainer>
                            ))}
                        </ListContainer>
                    ) : (
                        <NoData />
                    )}
                </PageContent>
                <Footer>
                    <FormButton onClick={onNext}>{nextBtnText}</FormButton>
                </Footer>
            </PageContainer>
        </>
    );
}
