import { Alert, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSelector, setNotification } from '../../state/page';

export default function NotificationDialog() {
    const dispatch = useDispatch();
    const notification = useSelector(notificationSelector);

    const handleClose = () => {
        dispatch(setNotification(''));
    };

    return (
        <Dialog open={!!notification} onClose={handleClose} maxWidth="sm">
            <Alert severity="warning">
                <div dangerouslySetInnerHTML={{ __html: notification }}></div>
            </Alert>
        </Dialog>
    );
}
