import Index from '../pages';
import Collation from '../pages/collation';
import Contract from '../pages/contract';
import ContractPreview from '../pages/contract-preview';
import History from '../pages/history';
import HistoryAnimation from '../pages/history-animation';
import Login from '../pages/login';
import LoginCollation from '../pages/login-collation';
import LoginHistoryAnimation from '../pages/login-history-animation';
import Registration from '../pages/registration';
import Stamp from '../pages/stamp';
import StampedContract from '../pages/stamped-contract';
import {
    COLLATION_PATH,
    CONTRACT_PATH,
    CONTRACT_PREVIEW_PATH,
    HISTORY_ANIMATION_PATH,
    HISTORY_PATH,
    INDEX_PATH,
    LOGIN_COLLATION_PATH,
    LOGIN_HISTORY_ANIMATION_PATH,
    LOGIN_PATH,
    REGISTRATION_PATH,
    STAMPED_CONTRACT_PATH,
    STAMP_PATH,
} from './routerPath';

const routes = [
    { path: INDEX_PATH, element: <Index />, isAuth: true, bg: 'bg-sky' },
    { path: REGISTRATION_PATH, element: <Registration />, isAuth: false, bg: 'bg-dark' },
    { path: LOGIN_PATH, element: <Login />, isAuth: false, bg: 'bg-sky' },
    { path: LOGIN_COLLATION_PATH, element: <LoginCollation />, isAuth: true, bg: 'bg-dark' },
    { path: LOGIN_HISTORY_ANIMATION_PATH, element: <LoginHistoryAnimation />, isAuth: true, bg: 'bg-light' },
    { path: CONTRACT_PREVIEW_PATH, element: <ContractPreview />, isAuth: true, bg: 'bg-sky' },
    { path: COLLATION_PATH, element: <Collation />, isAuth: true, bg: 'bg-dark' },
    { path: HISTORY_ANIMATION_PATH, element: <HistoryAnimation />, isAuth: true, bg: 'bg-light' },
    { path: STAMP_PATH, element: <Stamp />, isAuth: true, bg: 'bg-sky' },
    { path: CONTRACT_PATH, element: <Contract />, isAuth: true, bg: 'bg-sky' },
    { path: STAMPED_CONTRACT_PATH, element: <StampedContract />, isAuth: true, bg: 'bg-sky' },
    { path: HISTORY_PATH, element: <History />, isAuth: true, bg: 'bg-light' },
];

export default routes;
