import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import appLogoExpo from '../assets/images/app-logo-expo.png';
import FormButton from '../components/atom/FormButton';
import Header from '../components/molecules/Header';
import { LOGIN_COLLATION_PATH } from '../router/routerPath';
import { AbsoluteFlexBox, PageContainer, RelativeBox } from '../styles/style';

export default function Index() {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <>
            <Header hasBack={false} hasLogo={false} hasRegBtn white={false} />
            <PageContainer centerFlex hasHeader>
                <RelativeBox>
                    <AbsoluteFlexBox sx={{ marginBottom: theme.spacing(5) }}>
                        <Box component="img" src={appLogoExpo} alt="app-logo" sx={{ width: '80%' }} />
                    </AbsoluteFlexBox>
                    <FormButton onClick={() => navigate(LOGIN_COLLATION_PATH)}>開始</FormButton>
                </RelativeBox>
            </PageContainer>
        </>
    );
}
