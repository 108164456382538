import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { isShootingSelector, setIsShooting } from '../../state/page';
import { Flash, flashTimout } from '../../styles/style';

export default function ShootFlash() {
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const isShooting = useSelector(isShootingSelector);

    const handleClose = () => {
        setTimeout(() => {
            dispatch(setIsShooting(false));
        }, flashTimout);
    };

    return (
        <CSSTransition in={isShooting} nodeRef={nodeRef} timeout={flashTimout} unmountOnExit onEntered={handleClose}>
            <Flash />
        </CSSTransition>
    );
}
